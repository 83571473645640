import { Box } from '@mui/material'
import { useEffect, useState } from 'react';




const VideoPostDetail = (props) => {
    const [link, setLink] = useState("");
    useEffect(() => {
        setLink(props.post.content.link);
    }, []);

    return (
        <Box>
            <iframe src={link} alt={link} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" height="450" width="800"></iframe>
        </Box>
    )
};

export default VideoPostDetail;