import { Grid, Paper, Button, Radio, FormControl, FormLabel, RadioGroup, TextField } from '@mui/material';
import { FormControlLabel } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material'
import { Redirect, redirectTo } from '@reach/router';
import { Link } from '@reach/router'

const defaultValues = {
        username: "",
        title: "",
        tags: "",
        type:"textpost",
        text:"",
        link:"",
}


const SerializePost = (obj) => {
    if (obj.type == "textpost") {
        return JSON.stringify(
            {
                metadata: {
                    title: obj.title,
                    username: obj.username,
                    tags: obj.tags
                },
                content: {
                    type: obj.type,
                    text: obj.text,
                }
            }
        );
    } else if (obj.type == "videopost") {
        return JSON.stringify(
            {
                metadata: {
                    title: obj.title,
                    username: obj.username,
                    tags: obj.tags
                },
                content: {
                    type: obj.type,
                    link: obj.link,
                    text: obj.text
                }
            }
        );
    } else {
        return JSON.stringify(
                    {
                        metadata: {
                            title: obj.title,
                            username: obj.username,
                            tags: obj.tags
                        },
                        content: {
                            type: obj.type,
                            link: obj.link,
                            text: obj.text
                        }
                    }
                );
    }
}

// CreatePostButton makes a button that redirect to /newpost
export const CreatePostButton = () => {
    const [clicked, setClicked] = useState(false);

    const clickHandler = () => {
        setClicked(true);
    }

    return (
        <Box>
            <Link to="/newpost"><Button onClick={clickHandler}>Submit A Post</Button></Link>
        </Box>
    )
}

const CreatePost = () => {
    const [postVals, setPostVals] = useState(defaultValues);
    const [submitted, setSubmitted] = useState(false);
    const handleInputChange = (elem) => {
    const {name, value} = elem.target;
        setPostVals({
            ...postVals,
            [name]: value
        });
    };


    const handleSubmit = (event) => {
        event.preventDefault();
        fetch('https://lookingdown.xyz/api/posts', {
            method: 'POST',
            body: SerializePost(postVals)
        }
        );
        setSubmitted(true);
    }

    return (
        <Paper elevation={3} sx={{p: 3, maxWidth: 500}}>
        <form onSubmit={handleSubmit}>
        <Grid container spacing={0} alignItems="center" justifyConent="center" direction="column">
            <h1>Create New Post</h1>
        <Box p={2}>
        <TextField 
            id="username"
            name="username"
            label="Username"
            type="text"
            value={postVals.username}
            onChange={handleInputChange}
        ></TextField>
        </Box>
        <Box p={2}>
        <TextField
            id="title"
            name="title"
            label="Title"
            type="text"
            value={postVals.title}
            onChange={handleInputChange}
        ></TextField>
        </Box>
        <Box p={2}>
        <FormControl>
            <FormLabel>
                Post Type
            </FormLabel>
            <RadioGroup
                name="type"
                value={postVals.type}
                onChange={handleInputChange}
                row
            >
                <FormControlLabel
                    key="textpost"
                    value="textpost"
                    control={<Radio size="small"/>}
                    label="Text Post"/>
                <FormControlLabel
                    key="videopost"
                    value="videopost"
                    control={<Radio size="small"/>}
                    label="Video Post"/>
                <FormControlLabel
                    key="imagepost"
                    value="imagepost"
                    control={<Radio size="small"/>}
                    label="Image Post"/>
            </RadioGroup>
        </FormControl>
        </Box>
        { postVals.type === "videopost" &&
            <Box>
                <span>Only embedable links work right now!</span>
                <Box p={2}>
                <TextField
                    id="videolink" 
                    name="link"
                    label="Video Link"
                    type="text"
                    value={postVals.link}
                    onChange={handleInputChange}
                ></TextField>
                </Box>
                <Box p={2}>
                <TextField
                    id="text"
                    name="text"
                    label="Description"
                    type="text"
                    value={postVals.text}
                    onChange={handleInputChange}
                ></TextField>
                </Box>
            </Box>
        }
        { postVals.type === "textpost" &&
            <Box p={2}>
                <TextField
                    id="text" 
                    name="text"
                    label="Post Content"
                    type="text"
                    value={postVals.text}
                    onChange={handleInputChange}
                >
                </TextField>
            </Box>
        }
        { postVals.type === "imagepost" &&
            <Box>
                <Box p={2}>
                <TextField
                    id="imagelink"
                    name="link"
                    label="Image Link"
                    type="text"
                    value={postVals.link}
                    onChange={handleInputChange}
                 ></TextField>
                </Box> 
                <Box p={2}>
                 <TextField
                    id="imagedesc"
                    name="text"
                    label="Image Description"
                    type="text"
                    value={postVals.text}
                    onChange={handleInputChange}
                >
                </TextField>
                </Box>
            </Box>
        }
        <Box p={2}>
        <Button variant="contained" color="primary" type="submit">
            Submit
        </Button>
        </Box>
        { submitted === true &&
            <Redirect to="/" noThrow/>
        }
        <Button><Link to="/">Cancel</Link></Button>
        </Grid>
        </form>
        </Paper>
    )
}



export default CreatePost;
