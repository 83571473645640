import { useEffect, useState } from "react";
import * as React from 'react';
import Card from '@mui/material/Card';
import TextPost from './textpost'
import ImagePost from './imagepost'
import VideoPost from './videopost'
import WebPost from './webpost'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import { CreatePostButton } from "./postcreate";

// Posts is the rendering for our main page "/"
// We render a card for each posts ann call components based on post.content.type
const Posts = () => {
    const [posts, setPosts] = useState([]);
    const [cursor, setCursor] = useState("");
    const [moreAvailable, setMoreAvailable] = useState(true);

    /* TODO: Add in a cache for our cursor */

    const loadMore = async () => {
      const resp = await fetch("https://lookingdown.xyz/api/posts",{
        headers: {
          "Post-Cursor":cursor
        }
      });

      if (resp.headers.has('Post-Cursor')) {
        setCursor(resp.headers.get('Post-Cursor'));
      } else {
        setCursor("");
        setMoreAvailable(false);
      }
      const json = await resp.json();
      setPosts([...posts, ...json])
      console.log(resp);
    }

    useEffect(() => {
        const getPosts = async () => {
            const resp = await fetch(
                "https://lookingdown.xyz/api/posts"
            );
            const postsResp = await resp.json();
            console.log(resp.headers.get("Post-Cursor"));
            setCursor(resp.headers.get("Post-Cursor"));
            setPosts(postsResp);
        };

        getPosts();
    }, []);
    return (
        <div container spacing={0} alignItems="center" justifyContent="center">
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
        <h1>Posts</h1>
        <CreatePostButton></CreatePostButton>
        {
          posts.map((post) => (
        <div key={post.id}>
          <Card sx={{ width: 600, m: 2}} variant="outlined">
          { post.content.type == "videopost" &&
            <>
              <VideoPost post={post}></VideoPost>
              <TextPost post={post}></TextPost>
            </>
          }
          { post.content.type === "imagepost" &&
            <>
              <ImagePost post={post}></ImagePost>
              <TextPost post={post}></TextPost>
            </>
          }
          { post.content.type === "webpost" &&
          <>
            <WebPost post={post}></WebPost>
            <TextPost post={post}></TextPost>
          </>

          }
          {
            post.content.type === "textpost" &&
            <TextPost post={post}></TextPost>
          }
        </Card>
        </div>
          ))}
        { moreAvailable &&
          <Button onClick={loadMore}>Load More</Button>
        }
        </Grid>
        </div>
    )
};

export default Posts;
