import { useState } from "react";
import { useEffect } from "react";
import { Box } from '@mui/material'

// VideoPost is a component for rending a VideoPost on our main page
// Note: An induvidual video post is handled by VideoPostDetail
const VideoPost = (props) => {
    const [link, setLink] = useState("");
    useEffect(() => {
        setLink(props.post.content.link);
    },[props.link]);

    return (
        <Box>
            <iframe src={link} alt={link} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" height="338" width="600"></iframe>
        </Box>
            );
};

export default VideoPost;