import { useState } from "react";
import { useEffect } from "react";
import { CardMedia } from "@mui/material";


// ImagePost is a component for rendering a ImagePost on our main page
// Note: An induvidual image post is handled by ImagePostDetail
const ImagePost = (props) => {
    const [link, setLink] = useState("");
    useEffect(() => {
        setLink(props.post.content.link);
    },[]);

    return (
        <CardMedia
            component="img"
            height="338"
            src={link}
            alt={link}
        >
        </CardMedia> 
    );
};

export default ImagePost;