import { Button, Grid, Box, TextField, Typography} from '@mui/material';
import React, {useState} from 'react';
import { Redirect } from '@reach/router';


const defaultComment = {
    text: "",
    user: "",

}

const CreateComment = ({id}) => {
    const [comment, setComment] = useState(defaultComment);
    const [submitted, setSubmitted] = useState(false);

    const handleInputChange = (event) => {
        setComment({
            ...comment,
            [event.target.name]: event.target.value
        })
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        fetch(`https://lookingdown.xyz/api/comments/${id}`, {
            method: 'POST',
            body: JSON.stringify(comment)
        });
        setSubmitted(true);
    }
    return (
        <Grid>
            <Typography variant="body2" sx={{paddingLeft:1}}>Leave a comment:</Typography>
            <form onSubmit={handleSubmit}>
                <Box p={1}>
                    <TextField
                        size="small" 
                        label="Comment"
                        type="text"
                        name="text"
                        id="text"
                        value={comment.text}
                        onChange={handleInputChange}
                    />
                </Box>
                <Box p={1}>
                    <TextField
                        size="small"
                        label="Username"
                        type="text"
                        name="user"
                        id="user"
                        value={comment.user}
                        onChange={handleInputChange}
                    />
                </Box>
                <Box>
                    <Button color="primary" type="submit">
                        Submit
                    </Button>

                </Box>
            </form>
            {submitted===true &&
                <Redirect to={`/posts/${id}`}></Redirect>
            }
        </Grid>
    );
}

export default CreateComment;
