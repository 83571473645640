import { Typography } from "@mui/material"
import { CardContent } from "@mui/material";
import {Link} from '@reach/router';
import * as React from 'react';
import { useEffect, useState } from "react";



// TextPost is a component for rendering a text post on our main page.
// Note: Text Post is used in rendering Video, Image and Web posts for the generic title and text sections
// although this may change in the future
const TextPost = (props) => {
    const [title, setTitle] = useState("");
    const [text, setText] = useState("");
    const [id, setId] = useState("");
    const [user, setUser] = useState("");
    useEffect(()=>{
        setTitle(props.post.metadata.title);
        setText(props.post.content.text);
        setUser(props.post.metadata.username);
        setId(props.post.id);
    },[]);

    return (
        <CardContent>
            <Typography variant="h5" component="div">
                <Link class="titlelink" to={`/posts/${id}`}>{title}</Link>
            </Typography>
            <Typography sx={{paddingBottom: 1, fontSize: 12}} color="text.secondary">Posted by: {user}</Typography>
            <Typography variant="body2" color="text.secondary">
                {text}
            </Typography>
        </CardContent>
    )
}

export default TextPost;