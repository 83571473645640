import { Typography, Box, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";

export const Comment = (props) => {
    const [text, setText] = useState("");
    const [user, setUser] = useState("");
    const [datetime, setDatetime] =  useState("");

    const datetimeFormatter = (timestamp) => {
        const date = new Date(timestamp);
        return `${date.getFullYear()}-${date.getMonth()}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}`
    }

    useEffect(() => {
        setText(props.comment.text);
        setUser(props.comment.user);
        setDatetime(datetimeFormatter(props.comment.datetime));
    }, []);

    return (
        <Box sx={{paddingTop: 1}}>
        <Paper elevation={2} sx={{ paddingLeft: 1, paddingRight:1, lineHeight: 2}}>
            <Typography variant="body2" color="text.primary" component="div">{user} at {datetime}</Typography>
            <Typography variant="body1" color="text.primary" component="div">{text}</Typography>
        </Paper>
        </Box>
    )
}

export const Comments = (props) => {
    const [comments, setComments] = useState([]);
    useEffect(() => {
        const getComments = async () => {
            const response = await fetch(`https://lookingdown.xyz/api/comments/${props.id}`);
            const data = await response.json();
            setComments(data.comments);
        };
        getComments();
    },[props.id]);

    return (
        <Box key={props.id}>
            {
                comments.map(comment => (
                        <Comment comment={comment}></Comment>
                    )
                )
            }
        </Box>
    );
}

            /*
            { comments.comments.map((comment) =>(
                <Comment comment={comment}></Comment>
            ))
            }
            */
