

// WebPost is a component for rendering a WebPost on our main page
// Note: an induvlidual web post is handled by WebPostDetail
// NOTE: awaiting future impl.
// TODO:
//  - Implement
const WebPost = () => {
    return;
}

export default WebPost;
