import React, { useEffect, useState } from 'react';
import { Link } from '@reach/router';
import  Paper  from '@mui/material/Paper'
import Box from '@mui/material/Box'
import VideoPostDetail from './videopostdetail';
import ImagePostDetail from './imagepostdetail';
import TextPostDetail from './textpostdetail';
import { Comments } from './comment'
import { Typography } from '@mui/material';
import CreateComment from './createcomment';



// PostDetail is the component responsible for an induvidual posts content (i.e. single post view)
const PostDetail = ({id}) => {

    const [post, setPost] = useState({});

    useEffect(() => {
        const getPost = async () => {
          const response = await fetch(`https://lookingdown.xyz/api/posts/${id}`)
          const data = await response.json();
          setPost(data);
        }
        getPost();
      }, [id]);

      if (!Object.keys(post).length) return <div/>;
      
      return (
      <Box sx={
      { borderRadius: 1,
        pl:10,
        fontSize: 17,
        fontWeight: '700',
      }
      }>
        <Box sx={{paddingLeft: 0.5}}>
            <h1>{post.metadata.title}</h1>
        </Box>
        <Paper variant="outlined" elevation={2} sx={{ padding:1.5, maxWidth:800, borderRadius: 2, bgcolor: "#F5F5F5"}}>

          { post.content.type==="videopost" &&
            <VideoPostDetail post={post}></VideoPostDetail>
          }

          { post.content.type==="imagepost" &&
            <ImagePostDetail post={post}></ImagePostDetail>
          }

          <TextPostDetail post={post}></TextPostDetail>
          <Box sx={{paddingTop: 3}}>
            <Typography sx={{paddingLeft: 1}} variant="body3">Comments</Typography>
            <CreateComment id={id}></CreateComment>
            <Comments id={id}></Comments>
          </Box>
        </Paper>
        <p><Link to="/">Go back</Link></p>
      </Box>
      );
};

export default PostDetail;
