import { useEffect, useState } from 'react';
import { Paper, Box, Typography } from '@mui/material'





const TextPostDetail = (props) => {
    const [text, setText] = useState("");
    const [user, setUser] = useState("");
    useEffect(() => {
        setText(props.post.content.text);
        setUser(props.post.metadata.username);
    }, []);

    return (
        <Paper elevation={2} sx={{ paddingLeft:1 , paddingRight:1,lineHeight: 2}}>
            <Box>
                <Typography variant="body2" color="text.primary">{user}</Typography>
            </Box>
            <Box>
                <Typography variant="body1" color="text.primary">{text}</Typography>
            </Box>
        </Paper>
    )
};

export default TextPostDetail;