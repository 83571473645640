
import { Router } from '@reach/router';
import Posts from './components/posts'
import PostDetail from './components/postDetail'
import CreatePost from './components/postcreate'

import './App.css';

function App() {
  return (
    <Router>
      <Posts path="/"/>
      <PostDetail path="/posts/:id"/>
      <CreatePost path="/newpost"></CreatePost>
    </Router>
  );
}

export default App;
