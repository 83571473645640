import { useEffect, useState } from 'react';





const ImagePostDetail = (props) => {
    const [link, setLink] = useState("");
    useEffect(() => {
        setLink(props.post.content.link);
    }, []);

    return (
        <>
            <img height="450" src={link}/>
        </>
    )
};

export default ImagePostDetail;